import MovieStore from "./movie.store";
import NotificationStore from "./notifications.store";
import ReviewsStore from "./reviews.store";

// Not sure about that.
//  i read about context in React to share info between context ( which i need for errors/warning notifications )
//  but this seems to works just fine...
//  Best practice would be welcome here ! :)

const stores = {
  movieStore: new MovieStore(),
  notificationStore: new NotificationStore(),
  reviewsStore: new ReviewsStore()
};

export default stores