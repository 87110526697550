import { inject, observer } from "mobx-react"
import React from "react"
import _ from "lodash"
import { css } from '@emotion/css'
import { Container, Alert } from '@mui/material';

const NotificationList = ({ notificationStore }) => {

    // build your UI here
    return (
        <div className={css`
            margin-top: 5px;
            margin-bottom: 5px;
        `}>
            <Container maxWidth="md">
                {_.map(notificationStore.notifications, (notification) => <Alert severity={notification.level}>{notification.message.toString()}{ (notification.level === "error") ? " 🙂 But don´t worry, we're on it ! 🙂" : ""}</Alert>)}
            </Container>
        </div>

    )
};

export default inject("notificationStore")(observer(NotificationList));
