import _ from "lodash"
import { getRandomInt } from "../utils/random"

const moviedbURL = "https://api.themoviedb.org/3/"
const moviedbImagesURL = "https://image.tmdb.org/t/p/w500/"
class MovieDBClient {

    apiKey // String containing the API key to connect to MovieDB
    genres // mapping of genres ID -> name, fetched at instance time
    constructor(apiKey) {
        this.apiKey = apiKey


    }

    static getAvatarURL = (avatar) => {
        if (avatar) {
            // Check if it's a full URL or just a relative path from IMDB
            //  NOTE : there's seems to be a bug on the API.
            //  fully URLs ( i.e. from Gravatar ) starts with a / too,
            //  so let's handle that gracefully.
            return avatar.startsWith("/https") ? avatar.slice(1) : `${moviedbImagesURL}${avatar}`
        }
        // Return a placeholder picture
        return "https://cdn.pixabay.com/photo/2018/04/18/18/56/user-3331257__340.png"
    }

    getMovies = async ({ page = 1, path = "trending/movie/week" }) => {
        const jsonResp = await this.req(path, { page })

        const transform = (movie) => (
            {
                id: movie.id,
                title: movie.title,
                summary: movie.overview,
                poster: `${moviedbImagesURL}${movie.poster_path}`,
                releaseDate: new Date(movie.release_date).toLocaleDateString('en-us'),
                reviewsAvg: movie.vote_average,
                reviewsCount: movie.vote_count,
            }
        )
        return _.map(jsonResp.results, transform)
    }

    getRandomReviews = async (movieID) => {

        // Simple decision here.
        //  Consider that everything above 5 is considered as positive.
        //  Everything else will be negative. Yeah, 5/10 is MEDIOCRE :)
        const positiveThreshold = 5

        // Max retry before we give up looking for a good/bad review
        const maxRetry = 50

        const path = `/movie/${movieID}/reviews`
        // Get the first response, and the whole
        const jsonResp = await this.req(path)

        //To pick correctly a random review, get the total number of page
        const totalPages = jsonResp.total_pages

        const getRandomReview = async (filter) => {
            for (var i = 0; i < maxRetry && totalPages > 0; i++) {
                getRandomInt(1, totalPages)
                const jsonResp = await this.req(path, { page: getRandomInt(1, totalPages) })

                const filtered = _.filter(jsonResp.results, filter)
                const sample = _.sample(filtered)
                // if sample is undefined, then the filter function removed everything. Let's try again...
                if (sample) {
                    return sample
                }
            }
            return null
        }

        const positiveReview = await getRandomReview((review) => Number.isInteger(review.author_details.rating) && (review.author_details.rating > positiveThreshold))
        const negativeReview = await getRandomReview((review) => Number.isInteger(review.author_details.rating) && (review.author_details.rating <= positiveThreshold))

        const transform = (review) => ({
            author: review.author,
            avatar: MovieDBClient.getAvatarURL(review.author_details?.avatar_path),
            rating: review.author_details.rating,
            content: review.content
        })

        return {
            "positive": positiveReview ? transform(positiveReview) : null,
            "negative": negativeReview ? transform(negativeReview) : null
        }

    }

    getLanguageAvailability = async (movieID, iso_code) => {
        const path = `/movie/${movieID}/release_dates`
        const jsonResp = await this.req(path)
        // Check if the movie has came out in a given language
        //  Note: we actually check if a movie came out in a given country.
        //  Reason for that is that there's no proper way to check from TMDB api the presence
        //  of a translation... Or at least none that i've seen :(
        return _.filter(jsonResp.results, (elem) => (elem.iso_3166_1 === iso_code)).length > 0
    }

    // Generic request function for MovieDB
    req = async (path, urlParams) => {
        const formatedParams = new URLSearchParams(urlParams)
        const response = await fetch(`${moviedbURL}${path}?api_key=${this.apiKey}&${formatedParams}`)
        const jsonResp = await response.json()
        if (response.ok) {
            return jsonResp
        }

        // In case of an error, throw a rejected promise
        throw Error(`status code : ${response.status} - body : ${JSON.stringify(jsonResp)}`)

    }


}

export default MovieDBClient;