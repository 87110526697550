import { css } from '@emotion/css';
import { Alert, Box, Card, CardContent, CardMedia, Container, Divider, Fade, Grid, Modal, Rating, Typography } from '@mui/material';
import { inject, observer } from "mobx-react";

const ReviewsModal = ({ reviewsStore }) => {

  const style = {
    "margin-top": "10%",
    transform: 'translateZ(0)'
  };

  const open = reviewsStore.open

  const handleClose = () => reviewsStore.hide()

  const reviewCard = (title, avatar, content, score, author) => (
    <Grid item xs={12}>
      <Card sx={{ display: 'flex', whiteSpace: 'pre-wrap' }}>
        <CardMedia
          component="img"
          sx={{ width: 200, height: 200 }}
          image={avatar}
          alt="No avatar"
        />

        <CardContent>
          <Typography variant="h5">
            {title}
          </Typography>
          <Rating max={10} value={score} readOnly />
          <Divider />
          <Typography variant="h6" color="text.secondary" >
            Review by {author}
          </Typography>
          <Box>
            <Typography variant="body2" paragraph="true" color="text.secondary" >
              {content}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )

  const noReview = (label) => (
    <Grid item xs={12}>
      <Alert severity="info">No {label} reviews found for this item.</Alert>
    </Grid>
  )

  // build your UI here
  return (

    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Container className={css`${style}`}>
          <Grid container spacing={2} className={css`${style}`} >
            {reviewsStore.goodReview ? reviewCard("Positive review", reviewsStore.goodReview.avatar, reviewsStore.goodReview.content, reviewsStore.goodReview.rating, reviewsStore.goodReview.author) : noReview("positive")}
            {reviewsStore.badReview ? reviewCard("Negative review", reviewsStore.badReview.avatar, reviewsStore.badReview.content, reviewsStore.badReview.rating, reviewsStore.badReview.author) : noReview("negative")}
          </Grid>
        </Container>

      </Fade>
    </Modal>
  )
};

export default inject("reviewsStore")(observer(ReviewsModal));
