import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import stores from '../../stores/root.store';

const NavigationBar = () => (
  <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static">
      <Toolbar>
        <Button color="inherit" onClick={() => stores.movieStore.reset({ path: "/movie/popular" })}>Popular</Button>
        <Button color="inherit" onClick={() => stores.movieStore.reset({ path: "/trending/movie/week" })}>Weekly trends</Button>
        <Button color="inherit" onClick={() => stores.movieStore.reset({ path: "/movie/top_rated" })}>Top rated</Button>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, "text-align": "right" }}
        >
          Language
        </Typography>

        <Button color="inherit" onClick={() => stores.movieStore.reset({ language: "FR" })}>French</Button>
        <Button color="inherit" onClick={() => stores.movieStore.reset({ language: "US" })}>English</Button>
        <Button color="inherit" onClick={() => stores.movieStore.reset({ language: "ES" })}>Spanish</Button>
      </Toolbar>
    </AppBar>
  </Box>
);



export default NavigationBar;
