import { css } from '@emotion/css';
import { Container, CssBaseline } from '@mui/material';

import NavigationBar from './components/navigation/NavigationBar';
import NotificationsList from "./components/notifications/NotificationsList";
import MoviePage from "./components/pages/MoviePage";

const App = () => {
  return (
    <div className={css`
      min-height: 100vh;
      background-color: #F2F2F2;
    `}>
      <header className={css`
        position: relative;
        height: 150px;
        line-height: 150px;
        background-color: #555555;
        text-align: center;
        color: white;
        font-weight: bold;
        font-size: 1.5rem;
        background-image: url(https://cdn.wallpapersafari.com/91/98/utJZa2.jpg);
        background-size: contain;
      `}>
        Bienvenue dans Ask for movies

      </header>
      <NavigationBar />
      <main className={css`
        min-height: calc(100vh - 150px);
        display: flex;
      `}>
        <CssBaseline />


        <Container maxWidth="lg">
          <NotificationsList />
          <MoviePage />
        </Container>
      </main>
    </div>
  );
};

export default App;
