import { makeAutoObservable } from "mobx";

import _ from "lodash"

class NotificationStore {

  // This is surely not the best type-safe solution to create an enumeration,
  //  still it's easier to use than Symbols so...
  static NOTIFICATION_TYPES = Object.freeze({
    Info: "info",
    Warning: "warning",
    Error: "error",
  })

  notifications = []

  constructor() {
    makeAutoObservable(this);
    this.appendNotification(NotificationStore.NOTIFICATION_TYPES.Info, "Successfully loaded application !")
  }

  // Add a new notification
  appendNotification(level, message) {
    this.notifications = _.concat(this.notifications, {level, message})
  }
}

export default NotificationStore;
