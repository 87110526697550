import { makeAutoObservable } from "mobx";
import NotificationStore from "./notifications.store";

import stores from "./root.store";

import dbAccess from "../services/root";
class MovieAvailabilityStore {

  available = false;

  constructor(movieID) {
    this.client = dbAccess.tmdb;
    this.fetchLanguageAvailability(movieID)
    makeAutoObservable(this);
  }

  fetchLanguageAvailability(movieID) {

    this.available = false

    this.client.getLanguageAvailability(movieID, stores.movieStore.preferedLanguageISO)
      .then((available) => this.available = available)
      .catch((err) => {
        stores.notificationStore.appendNotification(
          NotificationStore.NOTIFICATION_TYPES.Error,
          `Error while fetching Language : ${err}`
        )
        this.available = false
      });
  }
}

export default MovieAvailabilityStore;
