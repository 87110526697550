import { makeObservable, observable } from "mobx";

import dbAccess from "../services/root";
import stores from "./root.store";

const loading = 1
const ready = 2

class ReviewsStore {
  goodReview = null
  badReview = null

  // Control weither to display or no the modal
  open = false
  state = loading

  display(movieID) {
    this.state = loading

    dbAccess.tmdb.getRandomReviews(movieID).then((reviews) => {
      this.goodReview = reviews.positive
      this.badReview = reviews.negative
      this.state = ready
      this.open = true
    }).catch((err) => stores.notificationStore.appendNotification("error", err.toString()))

  }

  hide() {
    this.open = false
    this.goodReview = null
    this.badReview = null
  }

  constructor() {
    makeObservable(this, {
      open: observable
    });
  }
}

export default ReviewsStore;
