import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { observer } from "mobx-react";
import { useState } from "react";
import MovieAvailabilityStore from "../../stores/movieAvailability.store";
import stores from "../../stores/root.store";

const MovieElement = observer((props) => {

  const title = props.title
  const id = props.id
  const poster = props.poster
  const summary = props.summary
  const releaseDate = props.releaseDate
  const reviewsCount = props.reviewsCount
  const reviewsAvg = props.reviewsAvg

  const [localStore] = useState(() => new MovieAvailabilityStore(id))


  const card = (
    <Card>
      <CardMedia
        sx={{ height: 140 }}
        image={poster}
        title={title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography gutterBottom variant="body2" component="div">
          Release date : {releaseDate}
        </Typography>
        {localStore.available ? <Alert severity="success">Available in your language !</Alert> : <Alert severity="info">Unavailable in your language</Alert>}
        <p>{JSON.stringify(localStore.movieAvailability)}</p>
        <Typography variant="body2" color="text.secondary">
          {summary}
        </Typography>
      </CardContent>
      <CardActions>
        <Typography variant="body2" color="text.secondary">
          <Rating name="customized-10" value={reviewsAvg} max={10} precision={0.25} readOnly />
          over {reviewsCount} reviews
        </Typography>
        <Button size="small" onClick={() => stores.reviewsStore.display(id)}>See random reviews</Button>

      </CardActions>
    </Card>
  )


  return card

})

export default MovieElement
