import { action, makeObservable, observable } from "mobx";
import NotificationStore from "./notifications.store";

import stores from "./root.store";

import _ from "lodash";
import dbAccess from "../services/root";
class MovieStore {
  popularMovies = [];

  page = 1;

  preferedLanguageISO = 'FR'
  path = '/movie/popular'

  constructor() {
    makeObservable(this, {
      popularMovies: observable,
      page: observable,
      fetchMovies: action,
    });

    this.client = dbAccess.tmdb;
    this.fetchMovies();
  }

  fetchMovies() {
    this.client
      .getMovies({ page: this.page, path: this.path })
      .then((movies) => this.popularMovies = _.concat(this.popularMovies, movies))
      .catch((err) =>
        stores.notificationStore.appendNotification(
          NotificationStore.NOTIFICATION_TYPES.Error,
          `Error while fetching Movies : ${err}`
        )
      );
  }

  // reset the state of the store, and fetch with an eventually different path
  reset({ path = this.path, language = this.preferedLanguageISO }) {
    this.page = 1
    this.popularMovies = []

    this.path = path
    this.preferedLanguageISO = language
    this.fetchMovies();
  }
}

export default MovieStore;
