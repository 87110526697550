import { css } from "@emotion/css";
import { Button, Grid } from "@mui/material";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import MovieElement from "./MovieShow";

const loadMore = (store) => {
  store.page += 1;
  store.fetchMovies();
};

const MovieList = ({ movieStore }) => (
  <div
    className={css`
      margin-top: 24px;
    `}
  >
    <Grid container columnSpacing={2} spacing={2}>
      {_.map(movieStore.popularMovies, (movie) => {
        return (
          <Grid
            container
            xs={12}
            md={3}
            lg={4}
            className={css`
              padding: 5px;
            `}
          >
            <MovieElement
              key={movie.title}
              id={movie.id}
              title={movie.title}
              summary={movie.summary}
              poster={movie.poster}
              actors={movie.actors}
              reviewsAvg={movie.reviewsAvg}
              reviewsCount={movie.reviewsCount}
              releaseDate={movie.releaseDate}
            />
          </Grid>
        );
      })}
    </Grid>
    <Button variant="contained" onClick={() => loadMore(movieStore)}>
      Load more...
    </Button>
  </div>
);

export default inject("movieStore")(observer(MovieList));
